export const pusherKey = "23b3ee59a89bdfa26ef3";
export const cluster = "ap2";
export const LanguageIds = {
  English: 101,
  Spanish: 102,
};
export const colorCodes = {
  1: "#B10095", // Im
  3: "#C0066B", // Activity
  5: "#9b6970", // IN
  6: "", // simulation
  7: "", //glossaries
  13: "#006F92", // Journal
  17: "#C9634C", // teacher resource
  18: "#2A55C3", // quiz
  22: "#277c79", // SR
  24: "#2A55C3", // assessment
  25: "#DE4901", // Reader
  26: "#030708",
  27: "#030708",
  29: "#030708",
  31: "#D10A2D", // WE
  32: "#1E4F97", // IMC
  33: "#a36136", // SEP
  34: "", // Anchoring
  35: "", // lab video
  36: "", // lab video
  37: "", // lab video
  38: "", // lab video
  39: "", // lab video
  44: "#D09600",
};
export const headerColorCodes = {
  1: "#E199D6",
  3: "#EE9DBF",
  5: "#d4a4ab",
  6: "",
  7: "",
  13: "#78C3DB",
  17: "#eba899",
  18: "#899ED6",
  22: "#6CB4B1",
  24: "#899ED6",
  25: "#EE9C74",
  26: "#353939", // external resource
  27: "#353939", // external resource
  29: "#353939", // external resource
  31: "#ED95A5",
  32: "#96B4E0",
  33: "#c08568",
  34: "",
  35: "",
  36: "",
  37: "",
  38: "",
  39: "",
  44: "#FED05B",
  45: "#d4a4ab",
};
export const docResources = [
  3, 13, 17, 18, 24, 25, 29, 32, 36, 37, 38, 39, 40, 41, 44,
];

export const backgroundText = {
  1: "IM",
  3: "A",
  5: "IN",
  6: "S",
  7: "G",
  13: "J",
  17: "TG",
  18: "Q",
  22: "SR",
  24: "AS",
  25: "R",
  26: "ER",
  27: "ER",
  29: "ER",
  31: "WE",
  32: "IMC",
  33: "SEP",
  34: "AP",
  35: "LV",
  36: "EDC",
  37: "IS",
  38: "SS",
  39: "VA",
  40: "LSP",
  41: "GLM",
  42: "VC",
  44: "SW",
  45: "LV",
};
export const hideQuizCampus = "ESC";
export const showCorrelationList = ["Correlation-School"];
export const showFormList = ["Form-School"];
export const hideNavList = ["Nav-School"];
export const spanishReaderGrade = [
  13, 15, 17, 26, 27, 28, 81, 82, 83, 97, 98, 99,
];
export const externalResourceTypes = [26, 27, 29];
export const initialBreadCrumb = {
  product_id: null,
  unit_id: null,
  subunit_id: null,
  level3_id: null,
  default_filter: null,
  resource_type: null,
  isMylibrary: null,
};
export const initialSelectedUnitData = {
  product_id: 0,
  unit_id: 0,
  subunit_id: 0,
  level3_id: 0,
  language_id: 101,
  default_filter_type: 2,
  default_filter: 0,
  resource_type: 0,
  isAssessment: false,
  currLevel: "product_id",
  isResourceType: false,
  isEDC: false,
  isMylibrary: false,
};

export const aesKey = "01234567890123456789012345678901";
export const aesIV = "0123456789012345";

export const archiveType = {
  assignment: "archived_assignments",
  lesson: "archived_lessons",
};
export const Type = {
  assignment: "assignments",
  lesson: "lessons",
};

export const hidePrebuiltGrade = [75, 76, 77, 78, 79, 80, 81, 82, 83, 84];

export const SDheaderColorCodes = {
  1: "#9D0084", // Im
  3: "#C0066B", // Activity
  5: "#8D12B8", // IN
  6: "#006943", // simulation
  7: "#3A2183", //glossaries
  13: "#005F7D", // Journal
  17: "#C9634C", // teacher resource
  18: "#0F379C", // quiz
  22: "#9A0202", // SR
  24: "#AE432A", // assessment
  25: "#B13E07", // Reader
  26: "#030708",
  27: "#030708",
  29: "#030708",
  31: "#96031D", // WE
  32: "#1E4F97", // IMC
  // 33: "#a36136", // SEP
  33: "#EF1D75", // SIA
  34: "#591010", // Anchoring
  35: "#7D6901", // lab video
  36: "#006767", // ED
  37: "", // lab video
  38: "", // lab video
  39: "", // lab video
  42: "#6C0B8E", // vocab card
  43: "#591010",
  45: "#8D12B8"
};
export const SDCardcolorCodes = {
  1: "#B10095", // Im
  3: "#B80164", // Activity
  5: "#8D12B8", // IN
  6: "#006943", // simulation
  7: "#3A2183", //glossaries
  13: "#006F92", // Journal
  17: "#C9634C", // teacher resource
  18: "#2A55C3", // quiz
  22: "#9A0202", // SR
  24: "#C9634C", // assessment
  25: "#E75E1C", // Reader
  26: "#030708",
  27: "#030708",
  29: "#030708",
  31: "#D10A2D", // WE
  32: "#1E4F97", // IMC
  33: "#EF1D75", // SIA
  34: "#591010", // Anchoring
  35: "#B09505", // lab video
  36: "#008686", // ED
  37: "", // lab video
  38: "", // lab video
  39: "", // lab video
  42: "#6C0B8E", // Vocab card
  43: "#591010", //driving question
  45: "#8D12B8", // Lesson vocab
  46: "", // Explain 
  47: "", // Fillin
  48: "",	//Lab Introduction
  49: "", //	Lab Quiz
  50: "", //	Extension
  51: "", //	Lesson Practice
  52: "", //	Introduction to the Reader	
  53: "",	 //Lab exercise
};

export const SDbackgroundText = {
  1: "IM",
  3: "A",
  5: "IN",
  6: "SM",
  7: "G",
  13: "J",
  17: "TG",
  18: "Q",
  22: "SR",
  24: "AS",
  25: "R",
  26: "ER",
  27: "ER",
  29: "ER",
  31: "WE",
  32: "IMC",
  33: "SIA",
  34: "AP",
  35: "LV",
  36: "ED",
  37: "IS",
  38: "SS",
  39: "VA",
  40: "LSP",
  41: "GLM",
  42: "VC",
  43: "D",
  45: "LV",
};

export const pageIds = {
  1: "Student Dashboard",
  2: "Teacher Dashboard",
  3: "Admin Dashboard",
  4: "Admin Page",
  5: "Workspace",
  6: "MyGroups",
  7: "Student Dashboard Reports",
  8: "Help",
  9: "Preview Dashboard",
  10: "Content Library",
};

export const categoryTitle = {
  "Physical Science": "#F04925",
  "Life Science": "#009243",
  "Earth and Space Science": "#224191",
};
