import { all, call } from "redux-saga/effects";
import { contentLibrarySaga } from "../containers/ContentLibrary/saga/contentLibrarySaga";
import { StudentdashboardSaga } from "../containers/NewStudentDashboard/saga/StudentdashboardSaga";
import { staarReadySaga } from "../containers/StaarReady/saga/staarReadySaga";

export function* watcherSaga() {
  yield all([
    call(contentLibrarySaga),
    call(StudentdashboardSaga),
    call(staarReadySaga),
  ]);
}
